/* eslint-disable react/no-danger */
import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { Content } from '../Content';
import { Illustration } from '../Illustration';
import { Text } from '../Text';

interface IStyledSection {
  reverse: boolean;
}

const StyledSection = styled.section<IStyledSection>`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: ${({ reverse }) => (reverse ? `row-reverse` : `row`)};
  margin-bottom: ${rem(`24px`)};

  ${({ theme }) => theme.breakpoints.phablet`
    margin-bottom: ${rem(`48px`)};
  `}
  ${({ theme }) => theme.breakpoints.tablet`
    margin-bottom: ${rem(`96px`)};
  `}
  &:last-child {
    margin: 0;
  }
  .section-content {
    flex: 1 0;
    padding-right: 0;
    margin-bottom: ${rem(`24px`)};
    ${({ theme }) => theme.breakpoints.phablet`
      margin-bottom: ${rem(`48px`)};
    `}
    ${({ theme }) => theme.breakpoints.tablet`
      flex: 1 0 calc(50% - 32px);
      margin-bottom: 0;
    `}
  }
  .title3 {
    max-width: 85%;
    ${({ theme }) => theme.breakpoints.phablet`
      max-width: 75%;
    `}
  }
  .image {
    flex: 1 0 auto;
    align-self: center;
    ${({ theme }) => theme.breakpoints.tablet`
      flex: 1 0 calc(50% - 32px);
    `}
  }
`;

interface ISection {
  className?: string;
  title: string;
  subtitle?: string;
  image: any;
  mdContent: string;
  displayId?: string;
  reverse?: boolean;
}

export const Section = ({
  className,
  title,
  subtitle,
  image,
  mdContent,
  displayId,
  reverse = false,
}: ISection) => {
  return (
    <StyledSection className={className} id={displayId} reverse={reverse}>
      <Content
        restrictWidth={false}
        className="section-content"
        disableSubtitlePadding
        noMargin
      >
        <Text type="title3" tag="h2" color="white_1">
          <p
            data-sal="slide-right"
            data-sal-delay="100"
            data-sal-easing="ease-in"
            style={subtitle && subtitle !== `` ? {} : { marginBottom: `24px` }}
          >
            {title}
          </p>
        </Text>
        {subtitle && subtitle !== `` ? (
          <Text type="subtitle1" tag="h3" color="white_1_45">
            <p
              data-sal-delay="100"
              data-sal-easing="ease-in"
              data-sal="slide-right"
            >
              {subtitle}
            </p>
          </Text>
        ) : null}
        <Text type="body1" tag="div" color="gray_3">
          <div
            data-sal-delay="100"
            data-sal-easing="ease-in"
            data-sal="slide-right"
            dangerouslySetInnerHTML={{ __html: mdContent }}
          />
        </Text>
      </Content>

      <Illustration
        align={`${reverse ? `flex-start` : `flex-end`}`}
        imageSrc={image}
        className="image"
        alt={`Illustration for ${title}`}
      />
    </StyledSection>
  );
};
