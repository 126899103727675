/* eslint-disable react/no-danger */
import React from 'react';
import { remark } from 'remark';
import recommended from 'remark-preset-lint-recommended';
import remarkHtml from 'remark-html';
import { graphql } from 'gatsby';
import { get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { getImage, withArtDirection } from 'gatsby-plugin-image';
import { Social } from '@/components/Social';
import Layout, { Main } from '../../components/Layout';
import { Section } from '../../components/Section';
import { Content } from '../../components/Content';
import { Text } from '../../components/Text';
import { Seo } from '../../components/SEO';
import { CallToAction } from '../../components/CallToAction';
import { MdContent } from '../../components/MdContent';
import { StyledProcesses } from './styles';

interface IProcessesPageTemplate {
  content?: any;
  processes: any;
}

interface IProcessesPage {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    contentfulPage: any;
    allContentfulProcess: any;
  };
  location: {};
}

const ProcessesPageTemplate = ({
  content,
  processes,
}: IProcessesPageTemplate) => {
  const { title, subtitle, body, ctaTitle, ctaHookId, ctaIcon, ctaBody } =
    content;
  const { body: mdBody } = body;

  return (
    <>
      <Content restrictWidth setMaxWidth="75%">
        <Text type="title1" tag="h2" color="white_1">
          {title}
        </Text>
        <Text type="subtitle1" tag="h3" color="white_1_45">
          {subtitle}
        </Text>
        <Text type="body1" tag="div" color="gray_3">
          <MdContent content={mdBody} />
        </Text>
      </Content>

      <Content restrictWidth setMaxWidth="90%" className="last">
        {processes.map(
          ({
            node: {
              title: processTitle,
              subtitle: processSubtitle,
              body: processBody,
              displayId,
              image,
            },
          }) => {
            const { body: mdBodyProcess } = processBody;
            const mdContentProcess = remark()
              .use(recommended)
              .use(remarkHtml)
              .processSync(mdBodyProcess)
              .toString();
            const uid = uuidv4();
            const images = withArtDirection(getImage(image.desktopImage), [
              {
                media: `(max-width: 768px)`,
                image: getImage(image.mobileImage),
              },
            ]);
            return (
              <Section
                className={displayId}
                key={uid}
                {...{
                  title: processTitle,
                  subtitle: processSubtitle,
                  mdContent: mdContentProcess,
                  uid,
                  displayId,
                  image: images,
                }}
              />
            );
          },
        )}
      </Content>

      <CallToAction
        hookId={ctaHookId}
        icon={ctaIcon}
        title={ctaTitle}
        body={ctaBody.ctaBody}
      />

      <Social />
    </>
  );
};

const ProcessesPageDesktopTemplate = ({
  processes,
}: IProcessesPageTemplate) => {
  return (
    <div className="body">
      <Content noMargin>
        {processes.map(
          ({
            node: {
              title: processTitle,
              subtitle: processSubtitle,
              body: processBody,
              displayId,
              image,
            },
          }) => {
            const { body: mdBodyProcess } = processBody;
            const mdContentProcess = remark()
              .use(recommended)
              .use(remarkHtml)
              .processSync(mdBodyProcess)
              .toString();
            const uid = uuidv4();
            const images = withArtDirection(getImage(image.desktopImage), [
              {
                media: `(max-width: 768px)`,
                image: getImage(image.mobileImage),
              },
            ]);
            return (
              <Section
                className={displayId}
                reverse
                key={uid}
                {...{
                  title: processTitle,
                  subtitle: processSubtitle,
                  mdContent: mdContentProcess,
                  uid,
                  displayId,
                  image: images,
                }}
              />
            );
          },
        )}
      </Content>
    </div>
  );
};

const ProcessesPage = ({ data, location }: IProcessesPage) => {
  const page = get(data, `contentfulPage`);
  const processes = get(data, `allContentfulProcess.edges`);
  const {
    title,
    subtitle,
    slug,
    metaDescription,
    metaTitle,
    templateKey,
    body,
    heroImage,
    svgTopPath,
    svgBottomPath,
    ctaTitle,
    ctaHookId,
    ctaIcon: pageCTAIcon,
    ctaBody,
  } = page;
  const hero = getImage(heroImage);
  const ctaIcon = getImage(pageCTAIcon);
  return (
    <StyledProcesses>
      <Layout
        location={location}
        coverImage={hero}
        topSvg={svgTopPath.svg.content}
        bottomSvg={svgBottomPath.svg.content}
        desktopHeading={
          <Content restrictWidth>
            <Text type="title1" tag="h2" color="white_1">
              {title}
            </Text>
            <Text type="subtitle1" tag="h3" color="white_1_45">
              {subtitle}
            </Text>
            <Text type="body1" tag="div" color="gray_3">
              <MdContent content={body.body} />
            </Text>
          </Content>
        }
        desktopBody={<ProcessesPageDesktopTemplate processes={processes} />}
        desktopCta={
          <CallToAction
            hookId={ctaHookId}
            icon={getImage(ctaIcon)}
            title={ctaTitle}
            body={ctaBody.ctaBody}
            maxWidth="100%"
          />
        }
      >
        <Seo
          title={title}
          metaTitle={metaTitle}
          metaDesc={metaDescription}
          cover={hero}
          slug={slug}
          templateKey={templateKey}
        />
        <Main background="transparent" disableVertPadding={false}>
          <ProcessesPageTemplate
            content={{
              title,
              subtitle,
              body,
              ctaTitle,
              ctaHookId,
              ctaIcon,
              ctaBody,
            }}
            processes={processes}
          />
        </Main>
      </Layout>
    </StyledProcesses>
  );
};

export default ProcessesPage;

export const ProcessesPageQuery = graphql`
  query ProcessesPage($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulPage(slug: { eq: $slug }) {
      templateKey
      title
      subtitle
      slug
      heroImage {
        gatsbyImageData(
          width: 560
          height: 900
          quality: 85
          placeholder: BLURRED
        )
      }
      svgTopPath {
        svg {
          content
        }
      }
      svgBottomPath {
        svg {
          content
        }
      }
      body {
        body
      }
      metaTitle
      metaDescription
      ctaTitle
      ctaHookId
      ctaIcon {
        gatsbyImageData(width: 48, quality: 85, placeholder: BLURRED)
      }
      ctaBody {
        ctaBody
      }
    }
    allContentfulProcess(sort: { fields: order }) {
      edges {
        node {
          title
          subtitle
          body {
            body
          }
          displayId
          image {
            mobileImage: gatsbyImageData(
              width: 200
              height: 150
              quality: 85
              placeholder: BLURRED
            )
            desktopImage: gatsbyImageData(
              width: 372
              height: 279
              quality: 85
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
`;
